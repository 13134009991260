body {
  margin: 0;
  background-color: #EDEDED !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  height: 100vh;
}

div.text-editor {
  font-family: inherit;
}

/* Video styles */

iframe {
  border-radius: 10px;
}

/* Carousel styles */

.slick-slide.slick-center div {
  transform: scale(1);
  transition: transform 700ms;
}

.slick-slide:not(.slick-center) > div:first-child {
  transform: scale(0.73);
  transition: transform 700ms;
}

.slick-slide { transform: translate(100%, 0); }

.slick-next:before, .slick-prev:before {
  content: none;
}

.slick-slider {
  height: 100%;
  display: flex;
  align-items: center;
}

::-webkit-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
  border-radius: 100px;
  background-color: #d2d7da;
  background-clip: content-box;
}